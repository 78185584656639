import React, { useRef, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { useLoader } from 'react-three-fiber'
import { Html } from "drei";
import * as THREE from 'three'
import { Section } from "./section";

function ImageElem({ url, }) {
  const texture = useLoader(THREE.TextureLoader, url)
  return (
    <mesh>
      <planeBufferGeometry attach="geometry" args={[1, 1]} />
      <meshBasicMaterial attach="material" map={texture} toneMapped={false} />
    </mesh>
  )
}
export default Image = ({
  domContent,
  children,
  bgColor,
  modelPath,
  positiona,
  positionb,
  positionc,
  scalea,
  scaleb,
}) => {
  const ref = useRef();
  /*  useFrame(() => (ref.current.rotation.y += 0.001)); */
  const [refItem, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    inView && (document.body.style.background = bgColor);
  }, [inView]);
  return (
    <Section factor={1.5} offset={1}>
      <group position={[positionb, positiona, positionc]} >
        <mesh ref={ref} position={[0, -70, 0]} scale={[scalea, scaleb, 0]}>
          <ImageElem url={modelPath} />
        </mesh>
        <Html fullscreen portal={domContent}>
          <div ref={refItem} className='container' >
            <h1 className='title'  >{children} </h1>
          </div>
          
        </Html>
      </group>
    </Section>
  );
};
