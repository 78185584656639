import React from "react";
import { ambientLight, directionalLight, spotLight } from 'react-three-fiber'

export default function Lights() {
  return (
    <>
      {/* Ambient Light illuminates lights for all objects */}
      <ambientLight intensity={0.15} />
      {/* Diretion light */}
      <directionalLight position={[100, 10, 50]} intensity={0.5} />
      <directionalLight
        castShadow
        position={[100, 10, 100]}
        intensity={0.5}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-far={200}
        shadow-camera-left={-10}
        shadow-camera-right={10}
        shadow-camera-top={10}
        shadow-camera-bottom={-10}
      />
      {/* Spotlight Large overhead light */}
      <spotLight intensity={1} position={[0, 200, 200]} castShadow />
    </>
  );
};

