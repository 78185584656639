import { Html, useGLTFLoader } from "drei";
import React, { useRef, useEffect } from 'react'
import { useFrame, useThree,  } from "react-three-fiber";
import { useInView } from "react-intersection-observer";
import { Section } from './section'
import {useKeyState} from 'use-key-state'
import { a } from "@react-spring/web";


function Model({ url }) {
  const gltf = useGLTFLoader(url, true);
  return <primitive object={gltf.scene} dispose={null} />;
  
}


const ModelBlender = ({
  domContent,
  children,
  bgColor,
  modelPath,
  positiona,
  positionb,
  positionc,
  scale,
  rota,
  rotb,
  rotc,
  turn
  
}) => {
  const ref = useRef();
  useFrame(() => (ref.current.rotation.y += turn
    
    ));
  
  const  a  = useKeyState(
      {
        a: "a"
        
      })
  useEffect(() => {
    if(a.pressed); { ref.current.rotation.y += 0.08 }  
    
   })
   
  
  
   

  const [refItem, inView] = useInView({
    threshold: 0,

  });

  
  


  useEffect(() => {
    inView && (document.body.style.background = bgColor);
  }, [inView]);
  return (
    <Section factor={1.5} offset={1}>
      <group position={[positionb, positiona, positionc]} >
     
       <mesh ref={ref} position={[0, -70, 0]} scale={[scale, scale, scale]} rotation={[rota, rotb, rotc]}>
          <Model url={modelPath} />
          
        </mesh>
    
        
      </group>
    </Section>
  );
};


export default ModelBlender;