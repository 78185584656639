import React, { useRef, useEffect } from 'react';
import { useInView } from "react-intersection-observer";
import { Html } from "drei";
import { Section } from "./section";


export default function Htmlport ({
  domContent,
  children,
  bgColor,
  positiona,
  positionb,
  positionc,
})  {
  const ref = useRef();
  /*  useFrame(() => (ref.current.rotation.y += 0.001)); */
  const [refItem, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    inView && (document.body.style.background = bgColor);
  }, [inView]);
  return (
    <Section factor={1.5} offset={1}>
      <group position={[positionb, positiona, positionc]} >
         <Html fullscreen portal={domContent}>
          {/* <div ref={refItem} className='container2'  > */}
           {children} 
         {/*  </div> */}
         </Html>
      </group>
    </Section>
  );
};
