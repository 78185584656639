import React from "react";
import {useNavigate} from "react-router-dom"

export default function Header() {
  let navigate = useNavigate();
  return (
    <header>
      <div className='header-inner'>
        <div className='logo'>
            <div className="btn"onClick={() => {navigate('/');}}>  
              JOHANNES BELZ
            </div>
        </div>
        <nav>
        <div className="contact"onClick={() => {navigate('/contact');}}>  
              CONTACT
            </div>
        </nav>
      </div>
    </header>
  );
}
