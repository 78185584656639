import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.scss";
import ContactText from "./components/contacttext";
import Header from "./components/header";
import Landing from './components/landing'

export default function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/contact" element={<ContactText/> }/>
      </Routes>
    </BrowserRouter>
  );
}
