import React from 'react'
import Lights from './lights'
import { Canvas, useFrame } from "react-three-fiber";
import { Suspense, useRef, useState, useEffect,  } from "react";
import { useLocation } from 'react-router-dom'
import ModelBlender from './model-blender'
import Image from './image'
import Loader from './loader'
import state from "./state";
import { PerspectiveCamera, OrbitControls,} from 'drei';
import Htmlport from './htmlport';
import { toContainElement } from '@testing-library/jest-dom';


export default function Landing(props) {
  	const location = useLocation()
	const [events] = useState();
	const domContent = useRef();
	const scrollArea = useRef();
	const onScroll = (e) => (state.top.current = e.target.scrollTop);
	useEffect(() => void onScroll({ target: scrollArea.current }), []);



	return (
	<>
		<Canvas concurrent colorManagement camera={{ position: [0, 0, 120], fov: 70 }} >
		{/* Lights Component */}
		<Lights />
		
		<Suspense fallback={null}>
		
			<fog attach="fog" args={["white", 120, 300]} />
		
			<ModelBlender
				domContent={domContent}
				bgColor='#ffffff'
				modelPath='/landscapen2.glb'
				positiona={280}
				positionb={0}
				positionc={20}
				rota={0.5}
				rotb={0}
				rotc={0.4}
				turn={0.002}
				scale={300}>
				
		
				
			</ModelBlender>
			
			
			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={240} positionb={0} positionc={0} >
			<div className='container' style={{ paddingLeft:'3%',paddingtop:'7%' }} >
			
			<h1 className='title'>
				
				<span>Johannes Belz </span>
				
						
			</h1>
			<h1 className='text' >
				
				<span>Architecture | Virtual Production | Photography </span>
				
						
			</h1>
			<h1 className='text3' style={{ fontWeight:'normal', paddingLeft:'1%', paddingTop:'8%' }}>
				<span style={{ fontWeight:'600' }}>CONTENT</span>
				<span>/ LANDING ON EARTH - exploration of natureculture and future</span>
				<span>/ ADOBE TIMBER HYBRIDS - enhancing wood constructions in architecture</span>
				<span>/ SUPPLY CHAIN URBANISM - mitigating climate change through global actions</span>	
				<span>/ Exhibitions and Photography</span>	
			</h1>
			<h1 className='text3' style={{ paddingLeft:'80%', paddingTop:'5%' }}>
				
				<span>-press a-</span>
				
			</h1>
			</div>
			</Htmlport>

			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-50} positionb={0} positionc={0} >
			<div className='container2' style={{ width:'90%', height:'90%',  justifyContent:'left', alignItems:'self-start'}}>
				<div  style={{ width:'70%', height:'90%',  justifyContent:'left', alignItems:'self-start', paddingRight:'5%'}}>	
					<h1 className='text' style={{ paddingBottom:'5%' }} >
           		 		1 // LANDING ON EARTH - exploration of natureculture and future   
					</h1>
					<img style={{ width: '100%', height: null }} src={"./Landing/Map.jpg"} alt="" />
					<div className='container2' style={{ width:'100%', height:'50%', justifyContent:'left', alignItems:'self-start'}}>
						<img style={{ width: '33%' }}  src={"/Detail/Grid erklärung [Konvertiert]-06.png"} alt="" />	
						<img style={{ width: '33%' }} src={"/Detail/Grid erklärung [Konvertiert]-01.png"} alt="" />
						<img style={{ width: '33%' }} src={"/Detail/Grid erklärung [Konvertiert]-04.png"} alt="" />	
					</div>
					<div className='container2' style={{ width:'100%', height:'50%', justifyContent:'left', alignItems:'self-start'}}>
						<img style={{ width: '33%' }}  src={"/Detail/Grid erklärung [Konvertiert]-05.png"} alt="" />	
						<img style={{ width: '33%' }} src={"/Detail/Grid erklärung [Konvertiert]-02.png"} alt="" />
						<img style={{ width: '33%' }} src={"/Detail/Grid erklärung [Konvertiert]-03.png"} alt="" />	
					</div>
				</div>
				<div className='container2' style={{ width:'30%', height:'90%',  justifyContent:'left', alignItems:'self-start'}}>	
					<h1 className='text2'style={{ textAlign:'justify', textAlignLast: 'unset' }} >
						
					<p>Your understanding of the world and your idea of reality are shaped by everything you know, all explicit and implicit knowledge, mediated by stories that surround us every day. You and I, we are not only listeners and readers, we produce, shape and form our reality.
						
						How do you understand our world? And how do you want to live?</p>

						In our imagination exists Gaia. Gaia is earth, she is "maker and destroyer" at the same time. A dynamic system, boundary-maintaining and contingent. Self-sustaining and stable only under certain circumstances.
						For many years we have lived in harmony with Gaia and understood ourselves as part of her. But over the years a typology of separation has been established.
						
						Exploitation and exclusion often prevail over connectedness, togetherness and integration. We destroy our livelihoods and thus make ourselves our own refugees.
						The root of all these problems is found in the relationship of modern societies to nature. In a society that sees nature as a resource and strives for constant. Infinite growth in a finite world?
						Does growth mean an increase in gross domestic product or an increase in human connectedness and ecological well-being?
					
						Let's go outside for a change.
						
						We humans and all parts of the animate and inanimate environment become co-authors, co-producers, . through new forms of interaction and intermixing.
						Do we think in dynamic assemblies of hybrids,
						and interrelations and understand ourselves as part of the same system, the question of and anthropocentric exceptionalism arises.
						But how can we think of non-human entities as integrated elements of our production of thought and knowledge? Defamiliarize our habits and think differently about the 'we'?
						The art of togetherness as a global survival strategy asks us to turn against established dichometries. We make distinctions and understand them as opposites, defining ourselves from a demarcation rather than commonality and
						focus.
						
						We want to produce spaces that overcome these problematic dualisms and tell stories of an expanded and more open society.
						Nature is culture. A network of natureculture constructed by human and non-human agents.
						Nature is transformed from something external to an umbrella term for all that is culturally produced.
						What role can we take in this new world and what access can we create?
						How does an idea, a thought construct, a conception manifest itself as physical, materialized space?
						
						We understand space as relational, as a processual set of relations, as a carrier of meaning, as something that puts things in relation to each other, as a medium.
						Through space we can rethink relationality,  perspective and reality.
						
						We assume that our relationship to the world is determined by two factors - the passive experience of the world and its active appropriation. 
			
						Spaces thus generate situated histories; they nurture, invent, and discover versions of living with one another and form communities.
						Spatial practices create a basis for connective practices.
						These lead us down new paths and create encounters we did not ask for. Walking becomes a pedagogical practice.
						Means empowering yourself to give answers. It helps us to better understand what it means to be responsibly
						to be.
						How are you today?
						Come along on a journey.
		
					</h1>
	    	 	</div>
			</div>
		
				
			</Htmlport>

			
			
			<ModelBlender
				domContent={domContent}
				bgColor='#ffffff'
				modelPath='/boden.glb'
				positiona={-370}
				positionb={0}
				positionc={-50}
				rota={0}
				rotb={0.5}
				rotc={0}
				turn={0.001}
				scale={13}>
				
			</ModelBlender>
			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-370} positionb={0} positionc={0} >
			<div className='container'>
			
				<h1 className='text3' style={{ paddingLeft:'80%', paddingTop:'50%' }}>
				
				<span>-press a-</span>
				
				</h1>
			</div>
			</Htmlport>


		

			<Htmlport domContent={domContent} bgColor='#000000' positiona={-700} positionb={0} positionc={0} >
				<div className='container2' style={{width:'90%',height:'120%', justifyContent:'space-around', paddingBottom:'4%'}}>
					<img style={{ width: 2200  }} src={"/Landing/5.jpg"} alt="" />				
				</div>
				<div className='container2' style={{width:'90%',height:'120%', justifyContent:'space-around', paddingBottom:'4%'}}>
					<img style={{ width: 2200  }} src={"/Landing/2.jpg"} alt="" />				
				</div>
				<div className='container2' style={{width:'90%',height:'120%', justifyContent:'space-around', paddingBottom:'4%'}}>
					<img style={{ width: 2200  }} src={"/Landing/3.jpg"} alt="" />				
				</div>
				<div className='container2' style={{width:'90%',height:'120%', justifyContent:'space-around', paddingBottom:'4%'}}>
					<img style={{ width: 2200  }} src={"/Landing/4.jpg"} alt="" />				
				</div>
				<div className='container2' style={{width:'90%',height:'120%', justifyContent:'space-around', paddingBottom:'4%'}}>
					<img style={{ width: 2200  }} src={"/Landing/1.jpg"} alt="" />				
				</div>
			</Htmlport>
			

			
			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-1800} positionb={0} positionc={0} >
				<div className='container2' style={{ width:'90%', height:'40%',  justifyContent:'left', alignItems:'self-start'}}>
					<h1 className='text' style={{ paddingRight:'5%' }} >
           		 
					2 // ADOBE TIMBER HYBRIDS - enhancing wood constructions in architecture
            		     
					</h1>
					<h1 className='text2' >
							Wood, like all bio-based building materials can be considered CO2 negative since their captured carbon will not be released into the atmosphere but stored
							and conserved in the building process. To limit CO2 emission and mitigate climate change we must rebuild the building sector with new production method and
							the focus on Wood as the main building material. The aim of this design research was to develop an adobe and timber composite construction that could enhance performance characteristic of current timber building components. With
							the use of digital manufacturing like robotics, CNC milling and additive manufacturing a module can be prefabricated that opens up possibilities to replace current on-site solutions for plasterwork and cementitious construction materials like screed.
					</h1>
	    	 	</div>
				<div className='container2' style={{width:'90%',  justifyContent:'space-around', alignItems:'self-start'}}>
					<div style={{width:'50%',  alignItems: 'stretch' }}>
						<div style={{width:'90%',  alignItems: 'self-start', justifyContent:'space-around'}}>
							<img style={{ width: '100%', paddingTop:'2%'}} src={"/Hybrid/hybrid5.jpg"} alt="" />
						</div>
							<img style={{ width: '100%', paddingRight:'10%', paddingTop:'7%', paddingBottom:'4%'}} src={"/Hybrid/step1-00.svg"} alt="" />
							<h1 className='text2' >
							 CLT + ADOBE Production Line
							</h1>
						<div style={{width:'90%',  alignItems: 'stretch', justifyContent:'space-around', paddingTop:'5%', paddingBottom:'5%'}}>
							<img style={{ width: '25%', padding:'1%' }} src={"/Hybrid/step1-02.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-08.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-03.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-07.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-01.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-06.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-04.svg"} alt="" />
							<img style={{ width: '25%', padding:'1%'  }} src={"/Hybrid/step1-05.svg"} alt="" />
						</div>
						<h1 className='text2' >
							Advantages of Adobe Timber Hybrid Construction
						</h1>
						<img style={{ width: '90%', paddingTop:'3%'}} src={"/Hybrid/research.jpg"} alt="" />
						
					</div>
					<div style={{width:'50%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%', paddingBottom: '2%'}} src={"/Hybrid/hybrid1.jpg"} alt="" />
						<img style={{ width: '100%', paddingBottom: '2%'}} src={"/Hybrid/hybrid2.jpg"} alt="" />
						<img style={{ width: '100%', paddingBottom: '2%'}} src={"/Hybrid/hybrid3.jpg"} alt="" />
						<img style={{ width: '100%', paddingBottom: '2%'}} src={"/Hybrid/hybrid4.jpg"} alt="" />
					</div>
					
				</div>
			</Htmlport>
			

			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-2400} positionb={0} positionc={0} >
				<div className='container2' style={{ width:'90%', height:'60%',  justifyContent:'left', alignItems:'self-start'}}>
					<h1 className='text' >
           		 
						3 // SUPPLY CHAIN URBANISM - mitigating climate change through global actions
            		     
					</h1>
					<h1 className='text2' >
					A Global Supply Chain is a dynamic worldwide network for companies to purchase and distribute goods and services from other parts of the globe. It involves global population, information, processes, resources, production, handling, distribution in global scale collaboration.The Belt and Road Initiative is a global development strategy adopted in 2013 by the Chinese Government, which aims to form a set of maritime and rail links between China, Europe and Africa. It involves vast infrastructures development and investments in nearly 70 countries. In the latest IPCC report, we are being urged to react on the global climate crisis as soon as possible to prevent the irreparable damage caused to our planet.
					The Belt and Road Initiative is the future of the world economy. It is not only a possibility to globalize and connect even more cultures and societies, but also to implement a market system and new routes that are healthier and more environmentally conscious, exactly what our planet needs.
					Millenia ago, the Earth was dominated by forests. But not anymore. Developed countries have spent the last twenty centuries cutting down trees and whole forests for livestock grazing, firewood for stoves, building materials, agriculture and fuel. However, even when a forest has been completely cleared, we can still restore the ecosystem. Forests are one of our greatest assets, a natural tool for storing CO2 and a haven of biodiversity.
					For us it is simple. More forest, better land distribution, but we must also not disregard the building and development of better cities for the growing population. If the Belt and Road Initiative is destined to be the main pipeline of the future, it will need a supporting infrastructure to change into a zero carbon emission supply chain. By creating a symbiotic urban structure along the main roads, the exchange of goods, services and information will be more efficient, and the possibility of reforestation will be real.
					</h1>
	    	 	</div>
				
				<div className='container2' style={{width:'90%',  justifyContent:'space-around', alignItems:'self-start'}}>
					<div style={{width:'50%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/SCU/globe.jpg"} alt="" />
						<img style={{ width: '100%', paddingBottom: '2%', paddingRight:'3%' , paddingLeft:'3%' }} src={"/SCU/diagrams.jpg"} alt="" />
						<img style={{ width: '100%', paddingRight:'3%' , paddingLeft:'3%' }} src={"/SCU/flow.jpg"} alt="" />
						<h1 className='text3' style={{ paddingBottom:'3%', paddingTop:'12%', paddingLeft:'3%' }} >
							Urban Aggregation, AI generated designs
						</h1>
						<div  style={{width:'100%',  justifyContent:'space-around', paddingRight:'3%', paddingLeft:'3%'}} >
							<img style={{ width: '66%', padding:'1%' }} src={"/SCU/urbaggrgif.gif"} alt="" />
							<img style={{ width: '34%', padding:'1%'}} src={"/SCU/urbaggr.jpg"} alt="" />
						</div>
					</div>
					<div style={{width:'47%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%', alignItems: 'flex-start', paddingBottom:'5%'}} src={"/SCU/plan.jpg"} alt="" />
						<img style={{ width: '99%' , alignItems: 'flex-start'}} src={"/SCU/urbaggr2.jpg"} alt="" />	
					</div>
				</div>
			
			</Htmlport>

			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-3100} positionb={0} positionc={0} >
				<div className='container2' style={{ width:'90%', height:'60%',  justifyContent:'left', alignItems:'self-start'}}>
					<h1 className='text' >
           		 
					x // Exhibitions and Photography
            		     
					</h1>
					
	    	 	</div>

				<div className='container2' style={{ width:'90%', height:'400%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'30%',  alignItems: 'stretch', paddingRight:'5%' }}>
					<h1 className='text3'>
						Landing on Earth, Exhibition
					</h1>
					</div>
					<div style={{width:'80%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Landing/LOE-8.jpg"} alt="" />
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Landing/LOE-12-2.jpg"} alt="" />
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Landing/LOE-10.jpg"} alt="" />
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Landing/LOE-7.jpg"} alt="" />
					</div>
	    	 	</div>

				<div className='container2' style={{ width:'90%', height:'200%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'30%',  alignItems: 'stretch', paddingRight:'5%'  }}>
					<h1 className='text3'>
						Iceland, Exhibition
					</h1>
					</div>
					<div style={{width:'80%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Iceland/1.jpg"} alt="" />
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/Iceland/2.jpg"} alt="" />
						
					</div>
	    	 	</div>
				
				<div className='container2' style={{ width:'90%', height:'210%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'30%',  alignItems: 'stretch', paddingRight:'5%'  }}>
					<h1 className='text3'>
						Wohnung BT, MTM Architektur
					</h1>
					</div>
					<div style={{width:'80%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/WohnungBT/4.jpg"} alt="" />
						<div  style={{width:'100%',  justifyContent:'space-around', paddingBottom:'2%'}} >
							<img style={{ width: '45.7%', paddingBottom: '2%', paddingRight:'2%'}} src={"/WohnungBT/5.jpg"} alt="" />	
							<img style={{ width: '54.3%', paddingBottom: '2%'}} src={"/WohnungBT/6.jpg"} alt="" />	
						</div>
						
					</div>
	    	 	</div>

				<div className='container2' style={{ width:'90%', height:'220%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'30%',  alignItems: 'stretch', paddingRight:'5%'  }}>
					<h1 className='text3'>
						PlugIN, CODE TU Berlin
					</h1>
					</div>
					<div style={{width:'80%',  alignItems: 'stretch' }}>
						
						<div  style={{width:'100%',  justifyContent:'space-around', paddingBottom:'2%'}} >
							<img style={{ width: '50%', paddingBottom: '2%', paddingRight:'1%'}} src={"/plugin/5.jpg"} alt="" />	
							<img style={{ width: '50%', paddingBottom: '2%', paddingLeft:'1%'}} src={"/plugin/6.jpg"} alt="" />	
						</div>
						<div  style={{width:'100%',  justifyContent:'space-around', paddingBottom:'2%'}} >
							<img style={{ width: '50%', paddingBottom: '2%', paddingRight:'1%'}} src={"/plugin/7.jpg"} alt="" />	
							<img style={{ width: '50%', paddingBottom: '2%', paddingLeft:'1%'}} src={"/plugin/8.jpg"} alt="" />	
						</div>
						
					</div>
	    	 	</div>

				 <div className='container2' style={{ width:'90%', height:'350%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'30%',  alignItems: 'stretch', paddingRight:'5%'  }}>
					<h1 className='text3'>
						Keramik und Lampe, 
						<span>Hannah Herzberg / Simon Schulte</span>
					</h1>
					</div>
					<div style={{width:'80%',  alignItems: 'stretch' }}>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/tablino/1.jpg"} alt="" />
						
						<div  style={{width:'100%',  justifyContent:'space-around', paddingBottom:'2%'}} >
							<img style={{ width: '50%', paddingBottom: '2%', paddingRight:'1%'}} src={"/tablino/2.jpg"} alt="" />	
							<img style={{ width: '50%', paddingBottom: '2%', paddingLeft:'1%'}} src={"/tablino/3.jpg"} alt="" />	
						</div>
						<img style={{ width: '100%',paddingBottom: '2%'}} src={"/tablino/4.jpg"} alt="" />
					</div>
	    	 	</div>

				

				
			
			
			</Htmlport>

			<Htmlport domContent={domContent} bgColor='#ffffff' positiona={-5300} positionb={0} positionc={0} >
				<div className='container2' style={{ width:'90%', height:'350%',  justifyContent: 'space-around', alignItems:'self-start'}}>
					<div style={{width:'100%',  alignItems: 'stretch' }}>
					<h1 className='text3'>
						<span>Impressum</span>
					</h1>
					<h1 className='text2'>
						<span>Angaben gemäß § 5 TMG</span>

						<span>Johannes Belz</span>
						<span>Akazienstrasse 9</span>
						<span>10823 Berlin</span>
						<span>Kontakt:</span>
						<span>Telefon: +49 1606911911</span>
						<span>E-Mail: jo.belz@icloud.com</span>
						
					</h1>
			
	    	 		</div>
				</div>
				</Htmlport>
			
		

		</Suspense>
	</Canvas><Loader /><div
		className='scrollArea'
		ref={scrollArea}
		onScroll={onScroll}
		{...events}>
			<div style={{ position: "sticky", top: 0 }} ref={domContent} />
			<div style={{ height: `${state.pages * 100}vh` }} />
		</div></>
	)
}
